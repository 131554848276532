/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import { useState, createContext, useEffect, useReducer } from 'react';
import { initializeApp } from 'firebase/app';
import { toast, ToastContainer } from 'react-toastify';

import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut
} from 'firebase/auth';

import { ReactSession } from 'react-client-session';
// action - state management
import { LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';
// project imports
import Loader from 'ui-component/Loader';
import {
    // FIREBASE_API_DEV,
    FIREBASE_API,
    DASHBOARD_PATH,
    SUBSCRIPTION_PATH
} from 'config';
// import ParityConfig from 'ParityConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios/api/axios';

ReactSession.setStoreType('localStorage');
initializeApp(FIREBASE_API);
// initializeApp(process.env.NODE_ENV === 'development' ? FIREBASE_API_DEV : FIREBASE_API);

const auth = getAuth();

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const [MaxDMLimit, setMaxDMLimit] = useState(0);
    // const BASE_URL = ParityConfig.getNodeUrl();
    const navigate = useNavigate();
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [dbUser, setDbUser] = useState(ReactSession.get('dbuser') || {});
    // const [dbLoginUser, setDbLoginUser] = useState(ReactSession.get('dbloginuser') || {});
    const [isExpired, setIsExpired] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [generalError, setGeneralError] = useState('');
    const [accessToken, setAccessToken] = useState(ReactSession.get('token') || '');
    const [requestInfo, setRequestInfo] = useState(ReactSession.get('requestInfo') || {});
    const [subscribeInfo, setSubscribeInfo] = useState(ReactSession.get('subscribe') || {});
    const [allDeals, setAllDeals] = useState([]);
    const [subsFetchAgain, setSubsFetchAgain] = useState(false);
    const [totalDeals, setTotalDeals] = useState(0);
    const location = useLocation();

    useEffect(() => {
        if (accessToken || ReactSession.get('token')) {
            (async () => {
                try {
                    const { data } = await axios.get(`subscription/mine`, {
                        headers: { Authorization: `Bearer ${accessToken || ReactSession.get('token')}` }
                    });
                    setSubscribeInfo(data);
                    if (!data?.expire || new Date(data.expire) < Date.now()) {
                        setIsExpired(true);
                    } else if (location.pathname === '/expired') {
                        navigate(DASHBOARD_PATH, { replace: true });
                    }
                } catch (err) {
                    console.log(err);
                } finally {
                    setSubsFetchAgain(false);
                    setIsLoading(false);
                }
            })();
        }
    }, [accessToken, ReactSession.get('token'), subsFetchAgain]);

    useEffect(
        () =>
            onAuthStateChanged(auth, async (user) => {
                if (user && dbUser?.uid) {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: {
                                id: user.uid,
                                email: user.email,
                                name: user.displayName,
                                image: user.photoURL
                            }
                        }
                    });
                } else if (!isLoading) {
                    dispatch({
                        type: LOGOUT
                    });
                    if (Object.keys(requestInfo).length) await logout();
                    localStorage.clear();
                } else {
                    // setIsLoading(false);
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, dbUser]
    );
    const handleEveryRequestInfo = (user) => {
        ReactSession.set('requestInfo', user || {});
        setRequestInfo(user || {});
    };
    const logout = () => {
        const logedOut = signOut(auth);
        ReactSession.set('dbuser', {});
        ReactSession.set('dbloginuser', {});
        ReactSession.set('token', '');
        ReactSession.set('subscribe', null);
        setAccessToken('');
        setDbUser({});
        // setDbLoginUser({});
        setSubscribeInfo({});
        localStorage.clear();
        handleEveryRequestInfo();
        navigate('/login', { replace: true });
        return logedOut;
    };

    const updateAuthCredientials = ({ token, user, subscription, stripeConnets }) => {
        ReactSession.set('token', token);
        ReactSession.set('subscribe', subscription || {});
        setSubscribeInfo(subscription || {});
        ReactSession.set('dbuser', { ...user, stripeConnets });
        setDbUser({ ...user, stripeConnets });
        // setDbLoginUser(user);
        setAccessToken(token);
        handleEveryRequestInfo({ ...user, leftCredit: user?.userExportCredit });
        return true;
    };

    // ================================================
    // old code for old email based auth START; no need of those
    // ==================================================

    const firebaseEmailPasswordSignIn = ({ email, password }) => {
        setIsLoading(true);
        console.log(email, password);
        signInWithEmailAndPassword(auth, email, password)
            .then((result) => {
                const { uid } = result.user;
                const body = {
                    uid,
                    password,
                    email
                };
                axios
                    .post(`auth/email-password-login`, body)
                    .then(({ data }) => {
                        updateAuthCredientials(data);
                        const isExpire = new Date(data?.user?.expire).valueOf() < Date.now();
                        if (data.user?.status !== 'Active' || isExpire) return navigate('/expired');
                        navigate(DASHBOARD_PATH);
                        return window.location.reload();
                    })
                    .catch(async (e) => {
                        await logout();
                        toast(e?.response?.data?.message || e.message, {
                            autoClose: 2500,
                            type: 'error'
                        });
                        setIsLoading(false);
                    });
            })
            .catch((e1) => {
                console.log(JSON.stringify(e1), '===');
                const { code, message } = e1;
                const msg = code === 'auth/user-not-found' || code === 'auth/wrong-password' ? `Credentials doesn't match` : message;
                toast(msg, {
                    autoClose: 2500,
                    type: 'error'
                });
                // alert(e.message);
                setIsLoading(false);
            });
    };

    const firebaseGoogleSignIn = () => {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(provider);
    };

    const firebaseRegister = (values) => {
        setIsLoading(true);
        const { email, password, name } = values;
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (result) => {
                // console.log('🧪 createUserWithEmailAndPassword context', result);
                const { uid } = result.user;
                const body = {
                    uid,
                    displayName: name,
                    password,
                    email
                };
                axios
                    .post(`auth/email-password-register`, body)
                    .then(({ data }) => {
                        updateAuthCredientials(data);
                        navigate('/unauthenticated');
                    })
                    .catch(async (e) => {
                        if (uid) {
                            await auth.currentUser.delete();
                        }
                        await logout();
                        toast(e.message || 'Something wont wrong', {
                            autoClose: 2500,
                            type: 'error'
                        });
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                let msg = 'Something wont wrong';

                if (error.code === 'auth/email-already-in-use') msg = 'User already register';
                toast(msg, {
                    autoClose: 2500,
                    type: 'error'
                });
                console.log(error.code, '===', error.message);
                setIsLoading(false);
            });
    };

    const firebaseRegisterWithOTP = (values) => {
        setIsLoading(true);
        const { email, password, OTP } = values;
        createUserWithEmailAndPassword(auth, email, password)
            .then(async (result) => {
                // console.log('🧪 createUserWithEmailAndPassword context', result);
                const { uid } = result.user;
                const body = {
                    uid,
                    password,
                    email,
                    OTP
                };
                axios
                    .put(`auth/veriry-otp-with-loggedIn-user`, body)
                    .then(({ data }) => {
                        updateAuthCredientials(data);
                        navigate(SUBSCRIPTION_PATH);
                        return window.location.reload();
                    })
                    .catch(async (eRR) => {
                        if (uid) {
                            await auth.currentUser.delete();
                        }
                        await logout();
                        setGeneralError(eRR.response?.data?.message || eRR.message);
                        // toast(e.message || 'Something wont wrong', {
                        //     autoClose: 2500,
                        //     type: 'error'
                        // });
                        setIsLoading(false);
                    });
            })
            .catch((error) => {
                let msg = 'Something wont wrong';
                if (error.code === 'auth/email-already-in-use') msg = 'User already register';
                setGeneralError(msg);
                console.log(error.code, '===', error.message);
                setIsLoading(false);
            });
    };

    const resetPassword = async (email) => sendPasswordResetEmail(auth, email);

    const updateProfile = () => {};
    if (state.isInitialized !== undefined && !state.isInitialized) {
        // || isLoading
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseRegister,
                firebaseEmailPasswordSignIn,
                login: () => {},
                firebaseGoogleSignIn,
                logout,
                resetPassword,
                updateProfile,
                dbUser,
                setDbUser,
                // dbLoginUser,
                isExpired,
                accessToken,
                setAccessToken,
                isLoading,
                generalError,
                setGeneralError,
                firebaseRegisterWithOTP,
                MaxDMLimit,
                setMaxDMLimit,
                subscribeInfo,
                requestInfo,
                handleEveryRequestInfo,
                allDeals,
                setAllDeals,
                setSubsFetchAgain,
                subsFetchAgain,
                totalDeals,
                setTotalDeals,
                setSubscribeInfo
            }}
        >
            <ToastContainer position="bottom-right" autoClose={2000} />
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
