// third-party
// assets
import { IconBiohazard, IconBrandStripe } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const Deal = {
    id: 'deal',
    // title: 'Generate',
    type: 'group',
    children: [
        {
            id: 'deal',
            title: 'Deal',
            type: 'item',
            url: '/deal',
            icon: IconBiohazard,
            breadcrumbs: false
        },
        {
            id: 'deal-create',
            title: 'Create Deal',
            type: 'item',
            url: '/deal-create',
            icon: IconBiohazard,
            breadcrumbs: false
        },
        {
            id: 'stripe-deal',
            title: 'Stripe Deal',
            type: 'item',
            url: '/stripe',
            icon: IconBrandStripe,
            breadcrumbs: false
        },
        {
            id: 'paddle-deal',
            title: 'Paddle Deal',
            type: 'item',
            url: '/paddle',
            icon: IconBiohazard,
            breadcrumbs: false
        }
    ]
};

export default Deal;
