import axios from 'axios';
import ParityConfig from 'paritybossConfig';
import { ReactSession } from 'react-client-session';

const BASE_URL = ParityConfig.getNodeUrl();
export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {
        Accept: 'x-www-form-urlencoded',
        'Content-Type': 'application/json'
    }
    // withCredentials: true
});

export const useAxiosPrivate = () => {
    const token = ReactSession.get('token');
    return axios.create({
        baseURL: BASE_URL,
        headers: {
            Authorization: `Bearer ${token}`
        }
        // withCredentials: true
    });
};
