export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};
// https://parityboss-server.vercel.app/api/v1/stripe-connect/stripe-auth-callback
// export const STRIPE_CLIENT_ID = `ca_Nwe2XyOd0arvvzyhyPe7DLvEu8h7C3YW`; // Taher
export const STRIPE_CLIENT_ID = `ca_Nw05S0JT7Jmjh8mVdcecErg3DSdsHc9D`;
export const STRIPE_CLIENT_ID_LIVE = `ca_Nw05LYwp3o9A0jhwK7l2oR0mgzQLhTCa`; // parityboss

// export const STRIPE_REDIRECT_URL = `https://d3b9-103-148-149-0.ngrok-free.app/api/v1/stripe-connect/stripe-auth-callback`;
export const STRIPE_REDIRECT_URL = `https://parityboss.onrender.com/api/v1/stripe-connect/stripe-auth-callback`;

// const redirect = process.env.NODE_ENV === 'development' ? STRIPE_REDIRECT_URL : STRIPE_REDIRECT_URL_DEV;

export const STRIPE_CONNECT_OAUTH = `https://connect.stripe.com/oauth/v2/authorize?response_type=code&client_id=${STRIPE_CLIENT_ID_LIVE}&scope=read_write&redirect_uri=${STRIPE_REDIRECT_URL}`;

export const FIREBASE_API = {
    apiKey: 'AIzaSyAX_kykuq1tcI1Lh9w7GLIdm4zPTltus7M',
    authDomain: 'paritybox-92628.firebaseapp.com',
    projectId: 'paritybox-92628',
    storageBucket: 'paritybox-92628.appspot.com',
    messagingSenderId: '490078583568',
    appId: '1:490078583568:web:180d58a320d3f265d58395',
    measurementId: 'G-34Y37YZYKM'
};
// tahertweetsy@gmail.com
export const FIREBASE_API_DEV = {
    apiKey: 'AIzaSyCNEtfr6fMYHxhvfhtl0brEuBk6zG2Po30',
    authDomain: 'dev-parityboss.firebaseapp.com',
    projectId: 'dev-parityboss',
    storageBucket: 'dev-parityboss.appspot.com',
    messagingSenderId: '745189975704',
    appId: '1:745189975704:web:b8d26b9dd50cbc299a12ed',
    measurementId: 'G-9PQM3HWQRY'
};

export const AUTH0_API = {
    client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
    domain: 'dev-w0-vxep3.us.auth0.com'
};

export const AWS_API = {
    poolId: 'us-east-1_AOfOTXLvD',
    appClientId: '3eau2osduslvb7vks3vsh9t7b0'
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

// export const DASHBOARD_PATH = '/dashboard/default';
export const DASHBOARD_PATH = '/deal';
export const UNAUTH_PATH = '/unauthenticated';
export const SUBSCRIPTION_PATH = '/subscription';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'theme4', // default, theme1, theme2, theme3, theme4, theme5, theme6
    // NOTE: tested this, but no changes are seen!!!!!!
    // presetColor: 'theme2', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
