import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
// tweetsy auth
const Login = Loadable(lazy(() => import('views/pages/authentication3/Login3')));
const ForgotPassword = Loadable(lazy(() => import('views/pages/authentication3/ForgotPassword3')));
// const Register3 = Loadable(lazy(() => import('views/pages/authentication3/Register3')));
const RegisterWithOTP = Loadable(lazy(() => import('views/pages/authentication3/RegisterWithOTP')));

// ==============================|| AUTH ROUTING ||============================== //

const AuthRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/register',
            element: <RegisterWithOTP />
        },
        {
            path: '/forgot-password',
            element: <ForgotPassword />
        }
    ]
};

export default AuthRoutes;
