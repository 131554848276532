/* eslint-disable prettier/prettier */
import { lazy } from 'react';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const Expired = Loadable(lazy(() => import('views/parityboss/expired')));
const Settings = Loadable(lazy(() => import('views/parityboss/Settings')));
const NewDeal = Loadable(lazy(() => import('views/parityboss/NewDeal')));
const StripeDeal = Loadable(lazy(() => import('views/parityboss/StripeDeal')));
const PaddleDeal = Loadable(lazy(() => import('views/parityboss/PaddleDeal')));
const Resource = Loadable(lazy(() => import('views/parityboss/Resource')));
const Deal = Loadable(lazy(() => import('views/parityboss/Deal')));
const UpdateDeal = Loadable(lazy(() => import('views/parityboss/UpdateDeal')));
const Subscription = Loadable(lazy(() => import('views/parityboss/subscription')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/deal', // this url adjusted with after subscribe redirect
            element: <Deal />
        },
        {
            path: '/deal-create',
            element: <NewDeal />
        },
        {
            path: '/stripe',
            element: <StripeDeal />
        },
        {
            path: '/paddle',
            element: <PaddleDeal />
        },
        {
            path: '/deal/edit/:dealId',
            element: <UpdateDeal />
        },
        {
            path: '/resource/:dealId',
            element: <Resource />
        },
        {
            path: '/subscription',
            element: <Subscription />
        },
        {
            path: '/settings',
            element: <Settings />
        },
        {
            path: '/expired',
            element: <Expired />
        }
    ]
};

export default MainRoutes;
