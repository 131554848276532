/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { Navigate, useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';

const routeStr = '/expired';
export default function ThemeRoutes() {
    const { isExpired } = useAuth();
    const authCommonRoutes = [
        { path: '/deal', element: <Navigate to={routeStr} replace /> },
        { path: '/deal-create', element: <Navigate to={routeStr} replace /> },
        { path: '/stripe', element: <Navigate to={routeStr} replace /> },
        { path: '/paddle', element: <Navigate to={routeStr} replace /> },
        AuthRoutes,
        MainRoutes
    ];

    const routes = [{ path: '/', element: <Navigate to={DASHBOARD_PATH} replace /> }, AuthRoutes, MainRoutes];

    return useRoutes(isExpired ? authCommonRoutes : routes);
}
