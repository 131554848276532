/* eslint-disable prettier/prettier */
// material-ui
import { useTheme } from '@mui/material/styles';
import logoDark from 'assets/images/logo-dark.svg';
import logo from 'assets/images/parityboss.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (<img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Tweetsy" width="130" />);
};

export default Logo;
