/* eslint-disable no-unused-vars */
/* eslint-disable lines-between-class-members */
/* eslint-disable spaced-comment */
/* eslint-disable class-methods-use-this */
// eslint-disable-next-line no-unused-vars
const NODE_BASE_URL_LOCAL = 'http://localhost:5000/api/v1/'; //localhost
// const NODE_DEV_VERCEL = 'https://dev-parityboss-server.vercel.app/api/v1/'; //dev vercel
// const NODE_BASE_URL = 'https://parityboss-server.vercel.app/api/v1/'; //live vercel
// const NODE_BASE_URL_LIVE_TEST = `https://live-test-parityboss-server.vercel.app/api/v1/`;
// const NODE_BASE_URL_REDNDER = 'https://parityboss.onrender.com/api/v1/'; //live vercel
const NODE_BASE_URL_LIVE = 'https://api1.parityboss.com/api/v1/'; //live vercel
class ParitybossConfig {
    getNodeUrl() {
        // return NODE_BASE_URL_LIVE;
        return process.env.NODE_ENV === 'development' ? NODE_BASE_URL_LOCAL : NODE_BASE_URL_LIVE;
    }
}

export default new ParitybossConfig();
