/* eslint-disable spaced-comment */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-arrow-callback */
// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import 'react-toastify/dist/ReactToastify.css';
// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import MacrodmProvider from 'contexts/MacrodmContext';

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <Locales>
                <NavigationScroll>
                    <AuthProvider>
                        <MacrodmProvider>
                            <>
                                <Routes />
                                <Snackbar />
                            </>
                        </MacrodmProvider>
                    </AuthProvider>
                </NavigationScroll>
            </Locales>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
